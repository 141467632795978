import { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { CenteredContainer } from "../authentication/CenteredContainer"

export function CreateOrganization() {
  const domainRef = useRef()
  
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { createOrganizationDomain } = useAuth()

  const navigate = useNavigate()

  async function handleOnSubmit(e) {
    e.preventDefault()

    if (!domainRef.current.value) {
      return setError("Please enter a domain name")
    }

    try {
      setError('')
      setLoading(true)
      await createOrganizationDomain(domainRef.current.value)

      navigate('/')
    } catch (error) {
      setError(`Failed to create an organization: ${error.message}`)
    }
    
    setLoading(false)
  }

  return (
    <CenteredContainer>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Create Teams</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleOnSubmit}>
            <Form.Group id="domain">
              <Form.Label>Name your group of teams</Form.Label>
              <Form.Control type="text" ref={domainRef} required />
            </Form.Group>
            
            <Button disabled={loading} className="w-100 mt-3" type="submit">Continue</Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">Just want to join an existing team? <Link to="/">Skip</Link></div>
    </CenteredContainer>
  )
}