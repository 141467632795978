import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAwNwp2sOMnhlr-YqCg6u0-1WhNJcoMovg",
  authDomain: "protofleets.firebaseapp.com",
  projectId: "protofleets",
  storageBucket: "protofleets.appspot.com",
  messagingSenderId: "79143692337",
  appId: "1:79143692337:web:14c689c3bc0ab6eeabcd10"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth()
export const db = getFirestore()
export default app