import { useState } from 'react'
import { Card, Button, Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { CenteredContainer } from './CenteredContainer'

export function Profile() {
  const { logout, currentUser } = useAuth()
  const [error, setError] = useState('')

  const navigate = useNavigate()
  
  async function handleLogout() {
    setError('')
    
    try {
      await logout()
      navigate('/')
    } catch {
      setError('Faild to log out')
    }
  }

  return (
    <CenteredContainer>
      <Card>
        <Card.Body>
          <h2 className='text-center mb-4'>Profile</h2>
          {error && <Alert variant='danger'>{error}</Alert>}
          <strong>Email:</strong> {currentUser.email}
          <Link to='/update-profile' className='btn btn-primary w-100 mt-3'>Update Profile</Link>
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Button variant='link' onClick={handleLogout}>Logout</Button>
      </div>
      <div className="w-100 text-center mt-2"><Link to="/">Skip</Link></div>
    </CenteredContainer>
  )
}