import { Container } from "react-bootstrap"

export function CenteredContainer({ children, maxWidth }) {
  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="w-100" style={{ maxWidth: maxWidth? maxWidth : "400px" }}>
        { children }
      </div>
    </Container>
  )
}