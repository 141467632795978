import { useEffect, useState, useReducer } from "react";
import { useAuth } from "../contexts/AuthContext";

import { db } from '../firebase'
import {
  collection, onSnapshot,
  addDoc, deleteDoc, doc,
  query, where,
  orderBy, serverTimestamp,
  updateDoc, getDoc, getDocs
} from 'firebase/firestore'

const ACTIONS = {
  SET_DOMAIN: 'set-domain',
  CREATE_TEAM: 'create-team',
}

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SET_DOMAIN:
      return {
        ...state,
        userDetails: payload.userDetails
      }
    default:
      return state
  }
} 

export function useDash() {
  const [state, dispatch] = useReducer(reducer, {
    // adminTeams: [],
    // userTeams: [],
    // domainTeams: [],
    userDetails: null,
    // isDomainAdmin: false
  })

  const { currentUser } = useAuth()

  const usersRef = collection(db, 'users')

  // useEffect 1
  useEffect(() => {
    console.log(currentUser)
    if (currentUser !== null) {
      getDoc(doc(db, 'user_email_domain_id', currentUser.email)).then((userDoc) => {

        const domains = []

        getDocs(collection(db, 'user_email_domain_id', currentUser.email, 'domains')).then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            domains.push({
              "domain_id": doc.id,
              ...doc.data()
            })
          })

          dispatch({
            type: ACTIONS.SET_DOMAIN,
            payload: {
              userDetails: {
                user_email: doc.id,
                domains: domains,
                default_domain: userDoc.data()['default_domain'] ?? null
              }
            }
          })
        }).catch((error) => {

          // no domain found in the collection user_email_domain_id/currentUser.email/domains
          dispatch({
            type: ACTIONS.SET_DOMAIN,
            payload: {
              userDetails: {
                user_email: currentUser.email,
                domains: [],
                default_domain: null,
              }
            }
          })
        })

      }).catch((error) => {

        // no user found in the collection user_email_domain_id
        dispatch({
          type: ACTIONS.SET_DOMAIN,
          payload: {
            userDetails: {
              user_email: currentUser.email,
              domains: [],
              default_domain: null,
            }
          }
        })
      })
    } else {
      dispatch({
        type: ACTIONS.SET_DOMAIN,
        payload: {
          userDetails: null
        }
      })
    }
    

  }, [currentUser])

  return state
}