import { useEffect, useState, useReducer } from "react";
import { useAuth } from "../contexts/AuthContext";

import { db } from '../firebase'
import {
  collection, onSnapshot,
  addDoc, deleteDoc, doc,
  query, where,
  orderBy, serverTimestamp,
  updateDoc, getDoc, getDocs,
  arrayUnion, arrayRemove,
  setDoc
} from 'firebase/firestore'
import { useAdmin } from "./useAdmin";

const ACTIONS = {
  // CHECK_ADMIN: 'check-domain',
  // CREATE_TEAM: 'create-team',
  // SET_ADMIN_TEAMS: 'set-admin-teams',
  SET_TEAMS: 'set-teams',
  SET_ALL_USERS: 'set-all-users'
}

function reducer(state, { type, payload }) {
  switch (type) {
    // case ACTIONS.SET_ADMIN_TEAMS:
    //   return {
    //     ...state,
    //     adminTeams: payload.adminTeams,
    //   }
    case ACTIONS.SET_TEAMS:
      return {
        ...state,
        teams: payload.teams,
      }

    case ACTIONS.SET_ALL_USERS:
      return {
        ...state,
        allUsers: payload.allUsers,
      }

    default:
      return state
  }
} 

export function useTeams(domainId) {
  const [state, dispatch] = useReducer(reducer, {
    // adminTeams: [],
    teams: [], // team object has team data and team roles
    allUsers: [],
  })

  // state needed
  // list of teams associated with the user for the input domain provided by user from the ui,
  // role of user in the team, as a map like admin: bool, manager: bool, user: bool


  const { currentUser } = useAuth()
  const { userIsAdmin, admin_domain_id, admin_domain_name } = useAdmin(domainId);

//   const usersRef = collection(db, 'users')

const fetchUserAndTeams = () => {
  console.log(currentUser)
  if (currentUser !== null && domainId != null) {

    if (userIsAdmin) {

      console.log(currentUser.email + 'is admin of' + domainId)

      const tempTeams = []

      getDocs(collection(db, 'domain', domainId, 'teams')).then((snapshot) => {
        snapshot.docs.forEach(doc => {
          if (doc.data() != null) {
            const teamData = {...doc.data(), teamId: doc.id}
            const isUser = teamData['team_users'].includes(currentUser.email)
            const isManager = teamData['team_managers'].includes(currentUser.email)
            const isAdmin = true
            tempTeams.push({
              team: teamData,
              roles: {
                isAdmin,
                isManager,
                isUser,
              }
            })
          }
        })

        dispatch({
          type: ACTIONS.SET_TEAMS,
          payload: {
            // adminTeams: tempAdminTeams
            teams: tempTeams
          }
        })
      })
      .catch((error) => {

        console.log('error fetching admin teams')
        console.log({error})
        dispatch({
          type: ACTIONS.SET_TEAMS,
          payload: {
            // adminTeams: []
            teams: []
          }
        })
      })
      
    } else {

      const tempTeams = []

      // const manager_query = query(collection(db, 'domain', domainId, 'teams'), where('team_managers', "array-contains", currentUser.email))

      // to do: need to make sure all team managers are also in team users
      const user_query = query(collection(db, 'domain', domainId, 'teams'), where('team_users', "array-contains", currentUser.email))

      getDocs(user_query).then((snapshot) => {
        snapshot.docs.forEach(doc => {
          if (doc.data() != null) {
            const teamData = {...doc.data(), teamId: doc.id}
            const isUser = true
            const isManager = teamData['team_managers'].includes(currentUser.email)
            const isAdmin = false
            tempTeams.push({
              team: teamData,
              roles: {
                isAdmin,
                isManager,
                isUser,
              }
            })
          }
        })

        dispatch({
          type: ACTIONS.SET_TEAMS,
          payload: {
            // adminTeams: tempAdminTeams
            teams: tempTeams
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: ACTIONS.SET_TEAMS,
          payload: {
            // adminTeams: []
            teams: []
          }
        })
      })

    }

    const tempUsers = []

    getDoc(doc(db, 'domain_users', domainId)).then((doc) => {
      if (doc.data() != null) {
        tempUsers.push(
          ...doc.data()['user_emails']
        )
      }

      dispatch({
        type: ACTIONS.SET_ALL_USERS,
        payload: {
          // adminTeams: tempAdminTeams
          allUsers: tempUsers,
        }
      })
    })
    .catch((error) => {

      console.log('error fetching admin teams')
      console.log({error})
      dispatch({
        type: ACTIONS.SET_ALL_USERS,
        payload: {
          // adminTeams: []
          allUsers: []
        }
      })
    })

  } else {
      console.log('else block from useEffect 1')
      dispatch({
        type: ACTIONS.SET_TEAMS,
        payload: {
          // adminTeams: []
          teams: []
        }
      })

      dispatch({
        type: ACTIONS.SET_ALL_USERS,
        payload: {
          // adminTeams: []
          allUsers: []
        }
      })
  }
  

}

  // useEffect 1
  useEffect(fetchUserAndTeams, [currentUser, domainId, userIsAdmin])

  // add new user to the domain
  // only domain admin is allowed to add a new user to domain

  async function addUserToDomain(newUserEmail) {
    console.log('inside addUserToDomain')
    console.log(newUserEmail)
    if (userIsAdmin) {
      const domainUsersDocRef = doc(db, 'domain_users', domainId)
      const userEmailDomainIdDocRef = doc(db, 'user_email_domain_id', newUserEmail)
      const userEmailDomainDocRef = doc(db, 'user_email_domain_id', newUserEmail, 'domains', domainId)
      await updateDoc(domainUsersDocRef, {
        user_emails: arrayUnion(newUserEmail)
      })

      await setDoc(userEmailDomainIdDocRef, {
        user_email: newUserEmail
      }, {merge: true})

      await setDoc(userEmailDomainDocRef, {
        domain: admin_domain_name,
        owner: false,
        created_at: serverTimestamp()
      })
      fetchUserAndTeams()
    }
  }

  async function addUserToSelectedTeam(userEmail, selectedTeam) {
    if (selectedTeam !== null && (selectedTeam.roles.isAdmin || selectedTeam.roles.isManager)) {
      const teamDocRef = doc(db, 'domain', domainId, 'teams', selectedTeam.team.teamId)
      await updateDoc(teamDocRef, {
        team_users: arrayUnion(userEmail)
      })
      fetchUserAndTeams()
    }
  }

  async function addProjectToSelectedTeam(newProject, selectedTeam) {
    if (selectedTeam !== null && (selectedTeam.roles.isAdmin || selectedTeam.roles.isManager)) {
      const teamDocRef = doc(db, 'domain', domainId, 'teams', selectedTeam.team.teamId)
      await updateDoc(teamDocRef, {
        projects: arrayUnion(newProject)
      })
      fetchUserAndTeams()
    }
  }

  async function addTeamToDomain(newTeam) {
    if (userIsAdmin && domainId != null && newTeam != null && currentUser != null) {
      const domainTeamsColRef = collection(db, 'domain', domainId, 'teams')
      const teamDocRef = await addDoc(domainTeamsColRef, {
        team_name: newTeam,
        team_managers: [currentUser.email],
        team_users: [currentUser.email],
        projects: [],
      })

      console.log('from addTeamtoDomain')
      console.log(teamDocRef.id)
      if (teamDocRef.id !== null) {
        await setDoc(teamDocRef, {
          id: teamDocRef.id
        }, {merge: true})

        fetchUserAndTeams()
      }
    }
  }

  return {...state, addUserToDomain, addUserToSelectedTeam, addProjectToSelectedTeam, addTeamToDomain}
}