import { useEffect, useState, useReducer } from "react";
import { useAuth } from "../contexts/AuthContext";

import { db } from '../firebase'
import {
  collection, onSnapshot,
  addDoc, deleteDoc, doc,
  query, where,
  orderBy, serverTimestamp,
  updateDoc, getDoc, getDocs
} from 'firebase/firestore'

const ACTIONS = {
  CHECK_ADMIN: 'check-admin',
  CREATE_TEAM: 'create-team',
  SET_TEAMS: 'set-teams',
}

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.CHECK_ADMIN:
      return {
        ...state,
        userIsAdmin: payload.userIsAdmin,
        admin_domain_id: payload.admin_domain_id,
        admin_domain_name: payload.admin_domain_name,
      }
    default:
      return state
  }
} 

export function useAdmin(domainId) {
  const [state, dispatch] = useReducer(reducer, {
    userIsAdmin: false,
    admin_domain_id: null,
    admin_domain_name: null,
  })

  // currently one domain owner can have only one domain, and cannot be part of other domain
  // It is changed by checking if the user is owner of the input domain

  const { currentUser } = useAuth()

//   const usersRef = collection(db, 'users')

  // useEffect 1
  useEffect(() => {
    console.log(currentUser)
    if (currentUser !== null && domainId != null) {
      getDoc(doc(db, 'domain_owners', currentUser.uid)).then((doc) => {

        if (doc.data() != null) {
          console.log('doc.data() if block from useEffect 1')
          const userIsAdminOfDomain = doc.data()['domain_id'] == domainId
          dispatch({
            type: ACTIONS.CHECK_ADMIN,
            payload: {
              userIsAdmin: userIsAdminOfDomain, // true,
              admin_domain_id: userIsAdminOfDomain ? doc.data()['domain_id'] : null,
              admin_domain_name: userIsAdminOfDomain ? doc.data()['domain'] : null,
            }
          })
        } else {

          console.log('doc.data() else block from useEffect 1')
          dispatch({
            type: ACTIONS.CHECK_ADMIN,
            payload: {
              userIsAdmin: false,
              admin_domain_id: null,
              admin_domain_name: null,
            }
          })

        }
        

      }).catch((error) => {

        // no user found in the collection domain_owners
        console.log('catch block from useEffect 1')
        dispatch({
          type: ACTIONS.CHECK_ADMIN,
          payload: {
            userIsAdmin: false,
            admin_domain_id: null,
            admin_domain_name: null,
          }
        })
      })
    } else {
        console.log('else block from useEffect 1')
        dispatch({
          type: ACTIONS.CHECK_ADMIN,
          payload: {
            userIsAdmin: false,
            admin_domain_id: null,
            admin_domain_name: null,
          }
        })
    }
    

  }, [currentUser, domainId])

  return state
}