import { Navbar, Nav, Button, Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'

export function NavbarComponent() {
  const { logout, currentUser } = useAuth()
  const [error, setError] = useState('')

  const navigate = useNavigate()
  
  async function handleLogout() {
    setError('')
    
    try {
      await logout()
      navigate('/')
    } catch {
      setError('Faild to log out')
    }
  }

  return (
    <>
      <Navbar bg='light' variant='light' className='mb-2' expand="sm" style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "1rem", paddingRight: "1rem"}}>
        <Navbar.Brand as={Link} to="/">ProtoFleets</Navbar.Brand>
        <Nav style={{display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", flexWrap: "nowrap",}}>
          <Nav.Link as={Link} to="/select-account-type">Change Account Type</Nav.Link>
          <Nav.Link as={Link} to="/user">Profile</Nav.Link>
          <Nav.Link as={Button} variant='link' onClick={handleLogout}>Logout</Nav.Link>
        </Nav>
      </Navbar>
      {error && <Alert variant='danger'>{error}</Alert>}
    </>
  )
}