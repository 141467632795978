import { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import { CenteredContainer } from "./CenteredContainer"

export function ForgotPassword() {
  const emailRef = useRef()
  
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const { resetPassword } = useAuth()

  async function handleOnSubmit(e) {
    e.preventDefault()

    console.log({
      "email": emailRef.current.value,
    })

    try {
      setMessage('')
      setError('')
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage('Check your inbox for further instructions')
      
    } catch {
      setError('Failed to reset the password')
    }
    
    setLoading(false)
  }

  return (
    <CenteredContainer>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Reset Password</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form onSubmit={handleOnSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            
            <Button disabled={loading} className="w-100 mt-3" type="submit">Reset Password</Button>
          </Form>
          <div className="w-100 text-center mt-2"><Link to="/login">Login</Link></div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">Need an account? <Link to="/signup">Sign Up</Link></div>
    </CenteredContainer>
  )
}