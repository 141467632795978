import { useRef, useState } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { CenteredContainer } from "../authentication/CenteredContainer"

export function SelectAccountType() {

//   const emailRef = useRef()
//   const passwordRef = useRef()
//   const passwordConfirmRef = useRef()
  
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { currentUser } = useAuth()

  const navigate = useNavigate()

  async function handleOnSubmit(e) {
    // e.preventDefault()

    // const promises = []
    // setError('')
    // setLoading(true)

    // if (passwordRef.current.value !== passwordConfirmRef.current.value) {
    //   return setError("Passwords do not match")
    // }

    // if (emailRef.current.value !== currentUser.email) {
    //   promises.push(updateUserEmail(emailRef.current.value))
    // }

    // if (passwordRef.current.value !== '') {
    //   promises.push(updateUserPassword(passwordRef.current.value))
    // }

    // Promise.all(promises).then(() => {
    //   navigate('/')
    // }).catch(() => {
    //   setError('Failed to update profile')
    // }).finally(() => {
    //   setLoading(false)
    // })

  }

  return (
    <CenteredContainer maxWidth="648px">
      <h2 className="text-center mb-4">Select Your Account Type</h2>
      <Row>
        <Col>
      <Card>
        <Card.Body>
          <Card.Title>User Account</Card.Title>
          {/* {error && <Alert variant="danger">{error}</Alert>} */}
          <Card.Text>Account for team members, and team managers to use and manage vehicles.</Card.Text>
          <Card.Text>
            <ul>
              <li>For employees</li>
              <li>Need to be included in a team by its admin</li>
              <li>Can switch between multiple teams created by an admin</li>
              <li>No charge</li>
            </ul>
          </Card.Text>
          <Button variant="secondary">Continue</Button>
        </Card.Body>
      </Card>
      </Col>
      <Col>
      <Card>
        <Card.Body>
          <Card.Title>Admin Account</Card.Title>
          {/* {error && <Alert variant="danger">{error}</Alert>} */}
          <Card.Text>Account for organization owners, to create and manage teams.</Card.Text>
          <Card.Text>
            <ul>
              <li>For employers, org heads</li>
              <li>Create teams and add team managers, users</li>
              <li>Create multiple teams under a group</li>
              <li>Subscription needed in future</li>
            </ul>
          </Card.Text>
          <Button as={Link} to="/create-org">Create</Button>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      <div className="w-100 text-center mt-2"><Link to="/">Skip</Link></div>
    </CenteredContainer>
  )

}