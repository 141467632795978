import { Signup } from "./authentication/Signup";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import { Dashboard } from "./vehicle-track-dashboard/Dashboard"
import { Login } from './authentication/Login'
import { ForgotPassword } from "./authentication/ForgotPassword";
import { PrivateRoute } from './PrivateRoute'
import { UpdateProfile } from "./authentication/UpdateProfile";
import { Profile } from "./authentication/Profile";
import { SignupAdmin } from "./authentication/SignupAdmin"
import { SelectAccountType } from "./vehicle-track-dashboard/SelectAccountType"
import { CreateOrganization } from "./vehicle-track-dashboard/CreateOrganization"

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/select-account-type" element={<PrivateRoute><SelectAccountType /></PrivateRoute>} />
          <Route path="/create-org" element={<PrivateRoute><CreateOrganization /></PrivateRoute>} />
          <Route path="/update-profile" element={<PrivateRoute><UpdateProfile /></PrivateRoute>} />
          <Route path="/user" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signup-create-domain" element={<SignupAdmin />} />
          <Route path="/login"element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
