import { useEffect, useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useVehicles } from '../../hooks/useVehicles'

function NoTeamMessage({userIsAdmin}) {
  return (
    <div>
      <div>You are not present in any team.</div>
      {userIsAdmin && <div>As an admin, you can create teams from admin panel</div>}
      {!userIsAdmin && <div>If you are a manager, you can create teams by changing account type or by requesting the organization admin to add you as team manager.</div>}
    </div>
  )
}

function TeamVehicleList({team, teamVehicles}) {
  const [searchAddVehicle, setSearchAddVehicle] = useState("")
  const filteredVehicles = teamVehicles.filter(vehicle => vehicle.name.includes(searchAddVehicle))

  return (
    <div className="my-2">
      <div className="h6">Team Vehicles</div>
      <div className="my-2">
        <input className="me-1" placeholder="type to search" type="text" value={searchAddVehicle} onChange={(e) => setSearchAddVehicle(e.target.value)} />
        {searchAddVehicle && <button className="me-1" onClick={() => setSearchAddVehicle("")}>Clear</button>}
      </div>
      <ul className="list-group">
        {filteredVehicles.map((vehicle) => <li className="list-group-item" key={vehicle.vehicleId}>{vehicle.name}</li>)}
      </ul>
    </div>
  )
}

function TeamProjectsList({team, selectedTeam, addProjectToSelectedTeam}) {
  const [searchAddProject, setSearchAddProject] = useState("")
  const filteredProjects = team.team.projects.filter(project => project.includes(searchAddProject))

  const addNewProjectInTeam = (e) => {
    if (selectedTeam !== null) {
      addProjectToSelectedTeam(searchAddProject, selectedTeam)
      setSearchAddProject("")
    }
  } 

  return (
    <div className="my-2">
      <div className="h6">Team Projects</div>
      <div className="my-2">
        <input className="me-1" placeholder="type to search" type="text" value={searchAddProject} onChange={(e) => setSearchAddProject(e.target.value)} />
        {searchAddProject && <button className="me-1" onClick={() => setSearchAddProject("")}>Clear</button>}
        <button disabled={!(filteredProjects?.length === 0 && searchAddProject.length > 1)} onClick={addNewProjectInTeam}>Add</button>
      </div>
      <ul className="list-group">
        {filteredProjects.map((project) => <li className="list-group-item" key={project}>{project}</li>)}
      </ul>
    </div>
  )
}

function TeamUsersList({team}) {
  console.log('from team users list')
  console.log({team})
  return (
    <div className="my-2">
      <div className="h6">Team User Emails</div>
      <ul className="list-group">
        {team.team.team_users.map((userEmail) => <li className="list-group-item" key={userEmail}>{userEmail}</li>)}
      </ul>
    </div>
  )
}

function TeamManagersList({team}) {
  console.log('from team managers list')
  console.log({team})
  return (
    <div className="my-2">
      <div className="h6">Team Manager Emails</div>
      <ul className="list-group">
        {team.team.team_managers.map((managerEmail) => <li className="list-group-item" key={managerEmail}>{managerEmail}</li>)}
      </ul>
    </div>
  )
}

function ManagerTeamList({teams, selectedTeamName, handleTeamSelect}) {
  const [searchAddTeam, setSearchAddTeam] = useState("")
  console.log('from team list')
  console.log({teams})
  const filteredTeams = teams.filter(team => team.team.team_name.includes(searchAddTeam))
  return (
    <div className="my-3">
      <div className="h6">My Teams</div>
      <div className="my-2">
        <input className="me-1" placeholder="type to search" type="text" value={searchAddTeam} onChange={(e) => setSearchAddTeam(e.target.value)} />
        {searchAddTeam && <button className="me-1" onClick={() => setSearchAddTeam("")}>Clear</button>}
      </div>
      <ul className="list-group">
        {filteredTeams.map((team) => <li onClick={() => handleTeamSelect(team.team.teamId)} className={team.team.team_name === selectedTeamName ? "list-group-item text-success" : "list-group-item"} key={team.team.id} style={{cursor: "pointer"}}>{team.team.team_name}</li>)}
      </ul>
    </div>
  )
}

function ManagerUserList({allUsers, selectedTeamUsers, selectedTeam, addUserToSelectedTeam}) {
  const [searchAddUser, setSearchAddUser] = useState("")
  console.log('from all user list')
  console.log({allUsers})
  const filteredUsers = allUsers.filter(user => user.includes(searchAddUser))
  console.log('selected team users from admin user list')
  console.log(selectedTeamUsers)

  const addUserInTeam = (userEmail) => {
    if (selectedTeam !== null) {
      addUserToSelectedTeam(userEmail, selectedTeam)
    }
  }

  return (
    <div className="my-3">
      <div className="h6">Domain Users</div>
      <div className="my-2">
        <input className="me-1" placeholder="type to search" type="text" value={searchAddUser} onChange={(e) => setSearchAddUser(e.target.value)} />
        {searchAddUser && <button className="me-1" onClick={() => setSearchAddUser("")}>Clear</button>}
        
      </div>
      <ul className="list-group">
        {filteredUsers.map((user) => {
          if (selectedTeamUsers.includes(user))
            return <li className="list-group-item text-success" key={user}><div><span>{user}</span></div></li>
          else
            return <li className="list-group-item" key={user}><div><span>{user}</span><button onClick={() => addUserInTeam(user)}>ADD</button></div></li>
        })}
      </ul>
    </div>
  )
}

export function ManagerTabContents({children, domain, teams, userIsAdmin, allUsers, addUserToSelectedTeam, addProjectToSelectedTeam}) {
  const [teamSelectedId, setTeamSelectedId] = useState(null)
  const {teamVehicles} = useVehicles(domain, teamSelectedId, true)

  console.log('team vehicles')
  console.log({teamVehicles})

  useEffect(() => {
    if (teams.length > 0) {
      setTeamSelectedId((currentValue) => {
        if (currentValue == null) {
          return teams[0]?.team?.teamId ?? null
        } else {
          return currentValue
        }
      })
    }
  }, [teams])

  const teamSelected = teamSelectedId ? teams.find(team => team.team.teamId === teamSelectedId) : null

  const handleTeamSelect = (teamId) => {
    setTeamSelectedId(teamId)
  }

  return (
    <div>
      {teams.length === 0 && <NoTeamMessage userIsAdmin={userIsAdmin}></NoTeamMessage>}
      <ManagerTeamList teams={teams} selectedTeamName={teamSelected?.team?.team_name ?? ""} handleTeamSelect={handleTeamSelect} />
      <ManagerUserList allUsers={allUsers} selectedTeamUsers={teamSelected?.team?.team_users ?? []} selectedTeam={teamSelected ?? null} addUserToSelectedTeam={addUserToSelectedTeam} />
      {teamSelected && (
        <div className="my-3">
          <div className="h4">{teamSelected.team.team_name}</div>
          {/* {
            teams.length > 1 && (
              <DropdownButton id="dropdown-basic-button" title="Change Team" size="sm">
                {
                  teams.map(team => <Dropdown.Item key={team.team.team_name} onClick={() => setTeamSelectedId(team.team.teamId)}>{team.team.team_name}</Dropdown.Item>)
                }
              </DropdownButton>
            )
          } */}
        </div>
      )}
      {teamSelected && <TeamManagersList team={teamSelected}></TeamManagersList>}
      {teamSelected && <TeamUsersList team={teamSelected}></TeamUsersList>}
      {teamSelected && <TeamProjectsList team={teamSelected} selectedTeam={teamSelected ?? null} addProjectToSelectedTeam={addProjectToSelectedTeam}></TeamProjectsList>}
      {teamSelected && <TeamVehicleList team={teamSelected} teamVehicles={teamVehicles}></TeamVehicleList>}
    </div>
  )
}