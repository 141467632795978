import { useEffect, useState } from 'react'
import { Card, Button, Alert, Container, Tab } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useDash } from '../../hooks/useDash'
import { CenteredContainer } from '../authentication/CenteredContainer'
import { DomainAdminTab } from './DomainAdminTab'
import { NavbarComponent as Navbar } from './Navbar'
import { RoleTabs } from './RoleTabs'
import { useAdmin } from '../../hooks/useAdmin'
import { AdminTabContents } from './AdminTabContents'
import { useTeams } from '../../hooks/useTeams'
import { ManagerTab } from './ManagerTab'
import { UserTab } from './UserTab'
import { ManagerTabContents } from './ManagerTabContents'
import { UserTabContents } from './UserTabContents'

export function Dashboard() {
  const [tab, setTab] = useState('team_u');

  const [domainSelected, setDomainSelected] = useState(null);

  function selectTab (t) {setTab(t)}

  const { userDetails } = useDash()
  
  const { userIsAdmin } = useAdmin(domainSelected)

  const { teams, allUsers, addUserToDomain, addUserToSelectedTeam, addProjectToSelectedTeam, addTeamToDomain } = useTeams(domainSelected)

  useEffect(() => {
    if (userDetails?.default_domain) {
      setDomainSelected(userDetails?.default_domain)
    }
    else if (userDetails?.domains.length > 0) {
      setDomainSelected(userDetails?.domains[0].domain_id)
    }
  }, [userDetails])

  console.log({teams})

  return (
    <div className='bg-light' style={{ minHeight: "100vh"}}>
      <Navbar />
      <Container fluid>
        {userDetails && domainSelected && <div className="h2 my-4">{userDetails.domains.find(domain => domain.domain_id === domainSelected).domain}</div>}
        <RoleTabs tab={tab} selectTab={selectTab}>
        {userIsAdmin && <DomainAdminTab title="Teams Admin" eventKey='domain_a'><AdminTabContents domain={domainSelected} teams={teams.filter(team => team.roles.isAdmin)} allUsers={allUsers} addUserToDomain={addUserToDomain} addUserToSelectedTeam={addUserToSelectedTeam} addTeamToDomain={addTeamToDomain} /></DomainAdminTab> }
        <ManagerTab title="Team Manager" eventKey='team_a'><ManagerTabContents domain={domainSelected} teams={teams.filter(team => team.roles.isManager)} allUsers={allUsers} addUserToSelectedTeam={addUserToSelectedTeam} addProjectToSelectedTeam={addProjectToSelectedTeam} /></ManagerTab>
        <UserTab title="Team User" eventKey='team_u'><UserTabContents domain={domainSelected} teams={teams.filter(team => team.roles.isUser)} userIsAdmin={userIsAdmin} /></UserTab>
        </RoleTabs>
      </Container>
    </div>
  )
}