import { useEffect, useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useVehicles } from '../../hooks/useVehicles'

function NoTeamMessage({userIsAdmin}) {
  return (
    <div>
      <div>You are not present in any team.</div>
      {userIsAdmin && <div>As an admin, you can create teams from admin panel</div>}
      {!userIsAdmin && <div>If you are a manager, you can create teams by changing account type or by requesting the organization admin to add you as team manager.</div>}
    </div>
  )
}

function UserVehicleList({team, teamVehicles}) {
  const [searchAddVehicle, setSearchAddVehicle] = useState("")
  const filteredVehicles = teamVehicles.filter(vehicle => vehicle.name.includes(searchAddVehicle))

  return (
    <div className="my-2">
      <div className="h6">My Vehicles</div>
      <div className="my-2">
        <input className="me-1" placeholder="type to search" type="text" value={searchAddVehicle} onChange={(e) => setSearchAddVehicle(e.target.value)} />
        {searchAddVehicle && <button className="me-1" onClick={() => setSearchAddVehicle("")}>Clear</button>}
      </div>
      <ul className="list-group">
        {filteredVehicles.map((vehicle) => <li className="list-group-item" key={vehicle.vehicleId}>{vehicle.name}</li>)}
      </ul>
    </div>
  )
}

function UserTeamProjectsList({team}) {
  const [searchAddProject, setSearchAddProject] = useState("")
  const filteredProjects = team.team.projects.filter(project => project.includes(searchAddProject))

  return (
    <div className="my-2">
      <div className="h6">Team Projects</div>
      <div className="my-2">
        <input className="me-1" placeholder="type to search" type="text" value={searchAddProject} onChange={(e) => setSearchAddProject(e.target.value)} />
        {searchAddProject && <button className="me-1" onClick={() => setSearchAddProject("")}>Clear</button>}
      </div>
      <ul className="list-group">
        {filteredProjects.map((project) => <li className="list-group-item" key={project}>{project}</li>)}
      </ul>
    </div>
  )
}

function TeamUsersList({team}) {
  console.log('from team users list')
  console.log({team})
  return (
    <div>
      <div className="h6">Team User Emails</div>
      <ul className="list-group">
        {team.team.team_users.map((userEmail) => <li className="list-group-item" key={userEmail}>{userEmail}</li>)}
      </ul>
    </div>
  )
}

function UserTeamList({teams, selectedTeamName, handleTeamSelect}) {
  const [searchAddTeam, setSearchAddTeam] = useState("")
  console.log('from team list')
  console.log({teams})
  const filteredTeams = teams.filter(team => team.team.team_name.includes(searchAddTeam))
  return (
    <div className="my-3">
      <div className="h6">My Teams</div>
      <div className="my-2">
        <input className="me-1" placeholder="type to search" type="text" value={searchAddTeam} onChange={(e) => setSearchAddTeam(e.target.value)} />
        {searchAddTeam && <button className="me-1" onClick={() => setSearchAddTeam("")}>Clear</button>}
      </div>
      <ul className="list-group">
        {filteredTeams.map((team) => <li onClick={() => handleTeamSelect(team.team.teamId)} className={team.team.team_name === selectedTeamName ? "list-group-item text-success" : "list-group-item"} key={team.team.id} style={{cursor: "pointer"}}>{team.team.team_name}</li>)}
      </ul>
    </div>
  )
}

export function UserTabContents({children, domain, teams, userIsAdmin}) {
  const [teamSelectedId, setTeamSelectedId] = useState(null)
  const {teamVehicles} = useVehicles(domain, teamSelectedId, false)

  useEffect(() => {
    if (teams.length > 0) {
      setTeamSelectedId((currentValue) => {
        if (currentValue == null) {
          return teams[0]?.team?.teamId ?? null
        } else {
          return currentValue
        }
      })
    }
  }, [teams])

  const teamSelected = teamSelectedId ? teams.find(team => team.team.teamId === teamSelectedId) : null

  const handleTeamSelect = (teamId) => {
    setTeamSelectedId(teamId)
  }

  return (
    <div>
      {teams.length === 0 && <NoTeamMessage userIsAdmin={userIsAdmin}></NoTeamMessage>}
      <UserTeamList teams={teams} selectedTeamName={teamSelected?.team?.team_name ?? ""} handleTeamSelect={handleTeamSelect} />
      {teamSelected && (
        <div className="my-3">
          <div className="h4">{teamSelected.team.team_name}</div>
          {/* {
            teams.length > 1 && (
              <DropdownButton id="dropdown-basic-button" title="Change Team" size="sm">
                {
                  teams.map(team => <Dropdown.Item key={team.team.team_name} onClick={() => setTeamSelectedId(team.team.teamId)}>{team.team.team_name}</Dropdown.Item>)
                }
              </DropdownButton>
            )
          } */}
        </div>
      )}
      {teamSelected && <TeamUsersList team={teamSelected}></TeamUsersList>}
      {teamSelected && <UserTeamProjectsList team={teamSelected}></UserTeamProjectsList>}
      {teamSelected && <UserVehicleList team={teamSelected} teamVehicles={teamVehicles}></UserVehicleList>}
    </div>
  )
}