import { Tabs, Tab } from 'react-bootstrap'
import { useState } from 'react';

export function RoleTabs({tab, selectTab, children}) {
  

  return (
    <Tabs activeKey={tab} onSelect={selectTab} className='mb-3'>
      { children }
    </Tabs>
  )
}