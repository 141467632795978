import { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import { CenteredContainer } from "./CenteredContainer"

export function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
    
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { login } = useAuth()

  const navigate = useNavigate()

  async function handleOnSubmit(e) {
    e.preventDefault()

    console.log({
      "email": emailRef.current.value,
      "password": passwordRef.current.value
    })

    try {
      setError('')
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      
      navigate('/')
    } catch (error) {
    setError(`Failed to log in: ${error.message}`)
    }
    
    setLoading(false)
  }

  return (
    <CenteredContainer>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Log In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleOnSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            
            <Button disabled={loading} className="w-100 mt-3" type="submit">Log In</Button>
          </Form>
          <div className="w-100 text-center mt-2"><Link to="/forgot-password">Forgot password?</Link></div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">Need an account? <Link to="/signup">Sign Up</Link></div>
      {/* <div className="w-100 text-center mt-2">Need to create a domain? <Link to="/signup-create-domain">Sign Up And Create Domain</Link></div> */}
    </CenteredContainer>
  )
}