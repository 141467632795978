import { useEffect, useReducer } from "react";
import { useAuth } from "../contexts/AuthContext";

import { db } from '../firebase'
import {
  collection, onSnapshot,
  addDoc, deleteDoc, doc,
  query, where,
  orderBy, serverTimestamp,
  updateDoc, getDoc, getDocs,
  arrayUnion, arrayRemove,
  setDoc
} from 'firebase/firestore'

const ACTIONS = {
  // CHECK_ADMIN: 'check-domain',
  // CREATE_TEAM: 'create-team',
  // SET_ADMIN_TEAMS: 'set-admin-teams',
  SET_TEAM_VEHICLES: 'set-team-vehicles',
  SET_TEAM_BOOKINGS: 'set-team-bookings'
}

function reducer(state, { type, payload }) {
  switch (type) {
    // case ACTIONS.SET_ADMIN_TEAMS:
    //   return {
    //     ...state,
    //     adminTeams: payload.adminTeams,
    //   }
    case ACTIONS.SET_TEAM_VEHICLES:
      return {
        ...state,
        teamVehicles: payload.teamVehicles,
      }

    case ACTIONS.SET_TEAM_BOOKINGS:
      return {
        ...state,
        teamBookings: payload.teamBookings,
      }

    default:
      return state
  }
}

export function useVehicles(domainId, teamId, forManager) {
  const { currentUser } = useAuth()

  const [state, dispatch] = useReducer(reducer, {
    // adminTeams: [],
    teamVehicles: [], // team object has team data and team roles
    teamBookings: [],
  })

  const fetchTeamVehicles = () => {
    console.log('from fetchTeamVehicles')
    if (currentUser.email !== null && domainId !== null && teamId !== null) { // need to check the user has access rights as team manager
      const tempVehicles = []

      if (forManager) {
        console.log('forManager is true')
        console.log('domain')
        console.log(domainId)
        console.log('teamId')
        console.log(teamId)
        console.log('current user email')
        console.log(currentUser.email)
        // const vehicle_query = query(collection(db, 'vehicles'), where('team_id', "==", teamId)) // need to check if domainId is to be used in query // failing since rules are not filters and the query do not meantion domain id hence it can fail for potential documents in the collection with different domain id.
        // https://firebase.google.com/docs/firestore/security/rules-query#rules_are_not_filters
        // const vehicle_query = query(collection(db, 'vehicles'), where('domain_id_team_id', "==", domainId + '_' + teamId)) // looks like firestore cannot always guarantee this query will always have read access. There could be some documents where it can pass and some where it can fail.
        // https://firebase.google.com/docs/firestore/security/rules-query#in_and_array-contains-any_queries
        // const vehicle_query = query(collection(db, 'vehicles'), where('domain_id', '==', domainId)) // if the rule contains two variables like domain_id and team_id, the query should also contain both // changed the rule to domain_id based only for testing.
        const vehicle_query = query(collection(db, 'vehicles'), where('domain_id', '==', domainId), where('team_id', '==', teamId)) // worked
        getDocs(vehicle_query).then((snapshot) => {
          console.log('team id based snapshot fetched ok')
          snapshot.docs.forEach(doc => {
            if (doc.data() != null) {
              const vehicleData = {...doc.data(), vehicleId: doc.id}
              // const isUser = vehicleData['vehicle_users'].includes(currentUser.email)
              tempVehicles.push(vehicleData)
            }
          })

          dispatch({
            type: ACTIONS.SET_TEAM_VEHICLES,
            payload: {
              teamVehicles: tempVehicles
            }
          })
        })
        .catch((error) => {

          console.log('error fetching team vehicles')
          console.log({error})
          dispatch({
            type: ACTIONS.SET_TEAM_VEHICLES,
            payload: {
              teamVehicles: []
            }
          })
        })
      } else {
        console.log('forManager is false')
        const vehicle_query = query(collection(db, 'vehicles'), where('vehicle_users', "array-contains", currentUser.email), where('team_id', '==', teamId))
        getDocs(vehicle_query).then((snapshot) => {
          snapshot.docs.forEach(doc => {
            if (doc.data() != null) {
              const vehicleData = {...doc.data(), vehicleId: doc.id}
              // const isUser = vehicleData['vehicle_users'].includes(currentUser.email)
              tempVehicles.push(vehicleData)
            }
          })

          dispatch({
            type: ACTIONS.SET_TEAM_VEHICLES,
            payload: {
              teamVehicles: tempVehicles
            }
          })
        })
        .catch((error) => {

          console.log('error fetching team vehicles')
          console.log({error})
          dispatch({
            type: ACTIONS.SET_TEAM_VEHICLES,
            payload: {
              teamVehicles: []
            }
          })
        })
      }
      
    }
  }

  useEffect(fetchTeamVehicles, [
    forManager, currentUser.email, domainId, teamId
  ])

  return state
}