import { createContext, useContext, useEffect, useState } from "react"
import { auth } from '../firebase'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword, signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  updateProfile
} from 'firebase/auth'

import { db } from '../firebase'
import {
  collection, onSnapshot,
  addDoc, deleteDoc, doc,
  query, where,
  orderBy, serverTimestamp,
  updateDoc, getDoc, setDoc
} from 'firebase/firestore'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  async function signup (email, password, userName, errorCallback) {
    try {
      await createUserWithEmailAndPassword(auth, email, password)
      await updateProfile(auth.currentUser, {
        displayName: userName
      })  
    } catch (error) {
      errorCallback(error)
    }
    
  }

  async function signupAdmin (email, password, domain, userName) {
    
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    await updateProfile(auth.currentUser, {
      displayName: userName
    })

    // below functions to be run from cloud functions, after the signup is run in client side, if the stripe customer id is present

    const domainDocRef = await addDoc(collection(db, 'domain'), {
      "domain": domain,
      "domain_admin_user_id": userCredential.user.uid,
      "domain_admin_user_email": userCredential.user.email
    })

    await setDoc(doc(db, 'domain_owners', userCredential.user.uid), {
      "domain": domain,
      "domain_id": domainDocRef.id,
    })

    await setDoc(doc(db, 'user_email_domain_id', userCredential.user.email), {
      "user_email": userCredential.user.email
    })

    await setDoc(doc(db, 'user_email_domain_id', userCredential.user.email, 'domains', domainDocRef.id), {
      "domain": domain,
      "owner": true
    })
  }

  async function login (email, password) {
    return await signInWithEmailAndPassword(auth, email, password)
  }

  async function logout () {
    return await signOut(auth)
  }

  async function resetPassword (email) {
    return await sendPasswordResetEmail(auth, email)
  }

  async function updateUserEmail (email) {
    return await updateEmail(auth.currentUser, email)
  }

  async function updateUserPassword (password) {
    return await updatePassword(auth.currentUser, password)
  }

  async function createOrganizationDomain (domain) {

    if (auth.currentUser == null) {
      throw "User need to sign in again"
    }

    // below functions to be run from cloud functions, after the signup is run in client side, if the stripe customer id is present

    const domainDocRef = await addDoc(collection(db, 'domain'), {
      "domain": domain,
      "domain_admin_user_id": currentUser.uid,
      "domain_admin_user_email": currentUser.email
    })

    await setDoc(doc(db, 'domain_owners', currentUser.uid), {
      "domain": domain,
      "domain_id": domainDocRef.id,
    })

    await setDoc(doc(db, 'user_email_domain_id', currentUser.email), {
      "user_email": currentUser.email
    })

    await setDoc(doc(db, 'user_email_domain_id', currentUser.email, 'domains', domainDocRef.id), {
      "domain": domain,
      "owner": true,
      "created_at": serverTimestamp()
    })

    await setDoc(doc(db, 'domain_users', domainDocRef.id), {
      "domain_name": domain,
      "user_emails": [
        currentUser.email
      ]
    })
  }

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribeAuth
  }, [])

  const exposed = {
    currentUser,
    signup,
    signupAdmin,
    login,
    logout,
    resetPassword,
    updateUserEmail,
    updateUserPassword,
    createOrganizationDomain
  }

  return (
    <AuthContext.Provider value={exposed}>
      {!loading && children}
    </AuthContext.Provider>
  ) // provide the children (render the children) only when the loading of data needed by them, is complete.
}

export function useAuth() {
  return useContext(AuthContext)
}