import { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { CenteredContainer } from "./CenteredContainer"

export function SignupAdmin() {
  const domainRef = useRef()
  const userNameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { signupAdmin } = useAuth()

  const navigate = useNavigate()

  async function handleOnSubmit(e) {
    e.preventDefault()

    if (!domainRef.current.value) {
      return setError("Please enter a domain name")
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    console.log({
      "email": emailRef.current.value,
      "password": passwordRef.current.value
    })

    try {
      setError('')
      setLoading(true)
      await signupAdmin(emailRef.current.value, passwordRef.current.value, domainRef.current.value, userNameRef.current.value)

      navigate('/')
    } catch (error) {
      setError(`Failed to create an account: ${error.message}`)
    }
    
    setLoading(false)
  }

  return (
    <CenteredContainer>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleOnSubmit}>
            <Form.Group id="domain">
              <Form.Label>Domain</Form.Label>
              <Form.Control type="text" ref={domainRef} required />
            </Form.Group>

            <Form.Group id="user-name">
              <Form.Label>User Display Name</Form.Label>
              <Form.Control type="text" ref={userNameRef} required />
            </Form.Group>

            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            
            <Button disabled={loading} className="w-100 mt-3" type="submit">Create Domain</Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">Already have an account? <Link to="/login">Log In</Link></div>
    </CenteredContainer>
  )
}