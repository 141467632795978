import { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { CenteredContainer } from "./CenteredContainer"

export function UpdateProfile() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { currentUser, updateUserEmail, updateUserPassword } = useAuth()

  const navigate = useNavigate()

  async function handleOnSubmit(e) {
    e.preventDefault()

    const promises = []
    setError('')
    setLoading(true)

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateUserEmail(emailRef.current.value))
    }

    if (passwordRef.current.value !== '') {
      promises.push(updateUserPassword(passwordRef.current.value))
    }

    Promise.all(promises).then(() => {
      navigate('/')
    }).catch(() => {
      setError('Failed to update profile')
    }).finally(() => {
      setLoading(false)
    })

  }

  return (
    <CenteredContainer>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Update Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleOnSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email} />
            </Form.Group>
            
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} placeholder="Leave blank to keep the same" />
            </Form.Group>
            
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} placeholder="Leave blank to keep the same" />
            </Form.Group>
            
            <Button disabled={loading} className="w-100 mt-3" type="submit">Update</Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2"><Link to="/user">Cancel</Link></div>
      <div className="w-100 text-center mt-2"><Link to="/">Skip</Link></div>
    </CenteredContainer>
  )

}